<template>
    <div class="PickUpGoods">
        <el-dialog
            title="查询商品基本信息"
            :visible.sync="dialogFormVisible"
            width="80%"
            top="60px"
            @close="close"
            @opened="handleQuery"
        >
            <el-card shadow="never" style="margin-top: 8px">
                <el-form
                    @submit.native.prevent
                    :inline="true"
                    style="text-align: right"
                    v-model="form"
                    ref="queryForm"
                    size="small"
                >
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="查询条件">
                                <el-input placeholder="商品名称/条码/助记码" v-model="form.search" />
                            </el-form-item>
                        </el-col>
                        <!--                        <el-col :span="8">-->
                        <!--                            <el-form-item label="机构名称">-->
                        <!--                                <el-input type="text" v-model="deptName" readonly />-->
                        <!--                            </el-form-item>-->
                        <!--                        </el-col>-->
                        <el-col :span="6">
                            <el-form-item label="品牌">
                                <ef-brand v-model="form.brandCode" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="类目">
                                <ef-category v-model="form.categoryCode" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item>
                                <el-button type="primary" @click="handleQuery">查询</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-card>

            <el-card shadow="never" style="margin-top: 8px" align="left">
                <div style="height: 500px; width: 100%">
                    <el-table
                        border
                        stripe
                        style="width: 100%"
                        size="mini"
                        max-height="500px"
                        :highlight-current-row="true"
                        ref="pickUpGoodsTable"
                        :data="tableData"
                        @select="handleSelected"
                        @select-all="selectAll"
                    >
                        <el-table-column width="40" type="selection" :selectable="rowCanSelectable" />
                        <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                        <el-table-column
                            prop="sku.name"
                            label="商品名称"
                            width="180"
                            fixed="left"
                            :show-overflow-tooltip="true"
                        />
                        <el-table-column
                            prop="sku.bars"
                            label="条码"
                            width="120"
                            fixed="left"
                            :show-overflow-tooltip="true"
                        >
                            <template slot-scope="scope">
                                <span>{{ scope.row.sku.bars.map((d) => d).join(' / ') }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="sku.specs" label="规格" width="120" />
                        <el-table-column prop="type" label="类型" width="80">
                            <template slot-scope="scope">
                                <span>{{ scope.row.sku.type | skuTypeDesc }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="status" label="状态" width="80">
                            <template slot-scope="scope">
                                <span>{{ scope.row.status | statusDesc }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="sku.category" label="类目" width="100" />
                        <el-table-column prop="sku.brand" label="品牌" width="100" :show-overflow-tooltip="true" />
                        <el-table-column prop="stock" label="库存" width="100" v-if="hasPrivilege('biz.stock.query')" />
                        <el-table-column
                            prop="actualStock"
                            label="实际库存"
                            width="100"
                            v-if="hasPrivilege('biz.stock.query')"
                        >
                            <template slot-scope="scope">
                                <span>{{ scope.row.actualStock }}</span>
                            </template>
                            <template slot="header">
                                <span>实际库存</span>
                                <el-tooltip effect="dark" placement="top">
                                    <div slot="content">
                                        实际库存，指门店中现有的库存，其中会包含电商销售后仍未发货或核销的库存，即实际库存=库存总数-预计欠货数量
                                    </div>
                                    <i class="el-icon-info"></i>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="availableStock"
                            label="可用库存"
                            width="100"
                            v-if="hasPrivilege('biz.stock.query')"
                        >
                            <template slot-scope="scope">
                                <span>{{ scope.row.availableStock }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="sku.unit" label="单位(小)" width="80" />
                        <el-table-column prop="sku.expirationDate" label="保质期" width="80" />
                        <el-table-column prop="factoryPrice" label="出厂价" width="100" v-if="showFactoryPrice">
                            <template slot-scope="scope">
                                <span>{{ scope.row.factoryPrice | moneyToThree }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="wholeSalePrice" label="批发价" width="100" v-if="showWholeSalePrice">
                            <template slot-scope="scope">
                                <span>{{ scope.row.wholeSalePrice | moneyToYuan }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="retailPrice" label="零售价" width="100" v-if="showRetailPrice">
                            <template slot-scope="scope">
                                <span>{{ scope.row.retailPrice | moneyToYuan }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="planCostPrice" label="计划成本" width="100" v-if="showPlanCostPrice">
                            <template slot-scope="scope">
                                <span>{{ scope.row.planCostPrice | moneyToFour }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="wmaCostPrice"
                            label="移动加权库存成本单价"
                            width="120"
                            v-if="showWmaCostPrice"
                        >
                            <template slot-scope="scope">
                                <!--移动加权成本单价默认单位为元，无需做转换-->
                                <span>{{ scope.row.wmaCostPrice }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <ef-pagination
                    v-if="dialogFormVisible"
                    :total="total"
                    :default-limit="form.limit"
                    :pageSizes="[10, 20, 50, 100, 150]"
                    @pageChange="pageChange"
                />
            </el-card>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false" size="small">取 消</el-button>
                <el-button v-if="multiCheckFlag" type="primary" @click="add" size="small">添 加</el-button>
                <el-button type="primary" @click="ok" size="small">添加并关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import EfPagination from 'components/EfPagination';
import Util from 'js/Util';
import GoodsCommon from 'js/GoodsCommon';
import MoneyUtils from 'js/MoneyUtils';
import { packFactor } from 'js/StockBizCommon';
import SurplusGoods from 'js/SurplusGoods';
import EfBrand from 'components/EfBrand';
import EfCategory from 'components/EfCategory';

export default {
    name: 'PickUpGoods',
    components: { EfPagination, EfCategory, EfBrand },
    props: {
        //此值控制是子组件自己关闭弹出框 还是父组件关闭
        selfCanClose: {
            default: true,
        },
        validateCanQueryGoodsFun: {
            type: Function,
            default: function () {
                return true;
            },
        },
        categoryCode: {
            type: String,
            default: '',
        },
        brandCode: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            deptName: '',
            form: {
                search: '',
                deptCode: '',
                deptType: null,
                goodsStatusIn: [],
                skuSinglePurchaseFlag: null,
                supplierCode: '',
                skuType: null,
                page: 1,
                limit: 20,
                brandCode: this.brandCode,
                categoryCode: this.categoryCode,
            },
            //是否展示价格字段
            showPricePropertyObj: {
                factoryPrice: true,
                wholeSalePrice: true,
                retailPrice: true,
                planCostPrice: true,
                wmaCostPrice: true,
            },
            total: 0,
            tableData: [],
            selected: [],
            lockRows: [],
            dialogFormVisible: false,
            factoryData: true,
            multiCheckFlag: true /*表单操作类型 true为提交多选数据 false为提交单选单选*/,
        };
    },
    filters: {
        bar(bars) {
            if (bars && bars.length) {
                return bars.join('/');
            }
            return bars;
        },
        skuTypeDesc(type) {
            return GoodsCommon.skuTypeDesc(type);
        },
        statusDesc(status) {
            return GoodsCommon.goodsStatusDesc(status);
        },
        moneyToYuan(money) {
            if (Util.isEmpty(money)) {
                return null;
            }
            return MoneyUtils.moneyToYuan(money);
        },
    },
    computed: {
        showFactoryPrice() {
            return (
                this.factoryData &&
                this.hasPrivilege('biz.price.factory.query') &&
                this.showPricePropertyObj.factoryPrice
            );
        },
        showWholeSalePrice() {
            return this.hasPrivilege('biz.price.wholeSale.query') && this.showPricePropertyObj.wholeSalePrice;
        },
        showRetailPrice() {
            return this.hasPrivilege('biz.price.retail.query') && this.showPricePropertyObj.retailPrice;
        },
        showPlanCostPrice() {
            return this.hasPrivilege('biz.price.plan.query') && this.showPricePropertyObj.planCostPrice;
        },
        showWmaCostPrice() {
            return (
                (this.hasPrivilege('biz.price.factory.query') || this.hasPrivilege('biz.price.wholeSale.query')) &&
                this.showPricePropertyObj.wmaCostPrice
            );
        },
    },
    methods: {
        checkFactory(data) {
            if (
                data.some((item) => {
                    return item.deptType == 3;
                })
            ) {
                this.$data.factoryData = false;
            } else {
                this.$data.factoryData = true;
            }
            this.$forceUpdate();
        },
        handleSelected(selection, row) {
            //处理点击选择按钮的操作
            if (!this.multiCheckFlag) {
                //单选操作类型，清空已勾选数据，并设置当前row勾选
                this.$refs.pickUpGoodsTable.clearSelection();
                this.$refs.pickUpGoodsTable.toggleRowSelection(row, true);
            }
        },
        selectAll() {
            //处理点击选择按钮的操作
            if (!this.multiCheckFlag) {
                this.$refs.pickUpGoodsTable.clearSelection();
            }
        },
        show(queryParam, deptName, multiCheckFlag) {
            if (multiCheckFlag) {
                this.multiCheckFlag = false;
            }
            this.form.brandCode = this.brandCode;
            this.form.categoryCode = this.categoryCode;
            if (Util.isEmpty(queryParam) || Util.isEmpty(queryParam.deptCode) || Util.isEmpty(deptName)) {
                this.$alert('未选择所属机构');
                return;
            }
            //删除分页参数，使用data中默认的分页
            delete queryParam.page;
            delete queryParam.limit;
            Util.copyProperties(queryParam, this.form);
            Util.copyProperties(queryParam.showPricePropertyObj, this.showPricePropertyObj);
            this.deptName = deptName;
            this.dialogFormVisible = true;
        },
        async handleQuery() {
            const rst = await this.$efApi.goodsApi.queryGoods(this.form);
            const data = [];
            for (const e of rst.data) {
                const goods = new Goods();
                Util.copyProperties(e, goods);
                if (Util.isEmpty(goods[packFactor])) {
                    //为包装因子设置默认值1
                    goods[packFactor] = 1;
                }
                goods.actualStock = e.actualStock;
                data.push(goods);
            }
            const availableStock = [];
            rst.data.forEach((item) => {
                availableStock.push(item.availableStock);
            });
            for (let i = 0; i < data.length; i++) {
                data[i].availableStock = availableStock[i];
            }
            this.tableData = data;
            // console.log('打印一下tableData', this.tableData);
            this.checkFactory(this.tableData);
            this.total = rst.count;
        },
        ok() {
            this.add();
            this.dialogFormVisible = false;
        },
        add() {
            const result = this.$refs['pickUpGoodsTable'].selection.filter((item) => {
                return this.rowCanSelectable(item);
            });
            if (this.$route.name.startsWith('menu.stock.surplus')) {
                if (!SurplusGoods.checkSurplusGoods(result)) {
                    this.$message.error('勾选项中含有未上架商品， 请先上架后在添加');
                    return;
                }
            }
            //点击添加时 将当前选择变为加锁状态
            this.lockRows = Array.from(new Set(result.concat(this.lockRows)));
            this.$emit('queryGoods', result);
        },
        rowCanSelectable(row) {
            const index = this.lockRows.findIndex((item) => {
                return item.code === row.code;
            });
            return index === -1;
        },
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.handleQuery();
        },
        close() {
            Object.assign(this.$data, this.$options.data());
        },
    },
};

class Goods {
    constructor() {
        this.code = '';
        this.deptCode = '';
        this.deptName = '';
        this.deptType = '';
        this.customBar = '';
        this.fastBar = '';
        this.planCostPrice = null;
        this.retailPrice = null;
        this.wholeSalePrice = null;
        this.factoryPrice = null;
        this.wmaCostPrice = null;
        this.skuCode = '';
        this.status = null;
        this.bigUnit = '';
        this.packFactor = 1;
        this.stock = null;
        this.skuType = null;
        this.sku = new Sku();
    }
}

class Sku {
    constructor() {
        this.code = '';
        this.name = '';
        this.specs = '';
        this.categoryCode = '';
        this.brandCode = '';
        this.unit = '';
        this.expirationDate = '';
        this.type = 0;
        this.bars = [];
        this.brand = '';
        this.category = '';
    }
}
</script>
